.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.phone-number-fade {
  -webkit-animation: fadeIn 4s;
  animation: fadeIn 4s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 325px;
  max-width: 100%;
  display: flex;
}

/* XXL Screens */
@media (min-width: 1400px) {
  /* Adjust the breakpoint as per your mixin */
  .logo {
    width: 325px;
  }
}

/* XL Screens */
@media (min-width: 1200px) {
  /* Adjust the breakpoint as per your mixin */
  .logo {
    width: 325px;
  }
}

/* MacBook Screens */
@media (max-width: 1440px) {
  /* Adjust based on your mixin */
  .logo {
    width: 325px;
  }
}

/* Tablet & Mobile */
@media (max-width: 768px) {
  /* Adjust breakpoint based on your mixin */
  .logo {
    width: 200px;
    margin-top: 0px;
  }
}

/* Extra Small Screens */
@media (max-width: 576px) {
  /* Adjust breakpoint based on your mixin */
  .logo {
    /* width: 155px; */
    margin-top: 0px;
    /* height: 35px; */
  }
}

/* Very Small Screens */
@media (max-width: 330px) {
  .logo {
    width: 140px;
  }
}
