.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
  /* background-color: aqua; */
  flex-direction: row;
}
.css-1583hmu-MuiStepLabel-root.MuiStepLabel-alternativeLabel {
  flex-direction: row;
}

.MuiStepLabel-root {
  flex-direction: row !important;
  align-items: center !important;
  margin: 10px 0px !important;
}

.MuiStepLabel-labelContainer {
  align-items: center !important;
  margin: auto !important;
}

.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;
}
